<template>
	<tr>
		<td>
			<img v-if="logo" class="building-logo-sm" :src="logo" alt="">
			<h5 v-else>No Logo</h5>
		</td>
		<td>
			{{ building.name }}
		</td>
		<td>{{ firstLandDistrict }}</td>
		<td>{{ firstLandSector }}</td>
		<td>
			<router-link :to="`/buildings/${building.token_addr}`" class="btn btn-primary btn-sm btn-block text-small">Manage</router-link>
		</td>
	</tr>
</template>

<script>
import "@google/model-viewer"

export default {
	name: "BuildingRow",
	props: {
		building: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			//
		}
	},
	computed: {
		firstLand: function() {
			return this.$store.state.profile.ownedLand?.find(a => a.x === this.building.land[0].spawn_chunk_x && a.y === this.building.land[0].spawn_chunk_y)
		},

		firstLandSector: function() {
			return this.firstLandAttr('Sector')
		},

		firstLandDistrict: function() {
			return this.firstLandAttr('District')
		},

		logo: function () {
			if (this.building.logo) {
				return this.building.logo.public_uri || `https://app.alphabatem.com/static/uploads/logos/${this.building.logo.uuid}.${this.building.logo.extension}`
			}

			return this.building.logo_src
		}
		//
	},
	methods: {
		firstLandAttr: function(attr) {
			return this.firstLand?.metadata.attributes.find(a => a.trait_type === attr).value
		},
	}
}
</script>

<style scoped>
.alert h3 {
	color: white;
	margin-bottom: 0;
}

h4 {
	font-size: 1em;
}

h5 {
	font-size: 0.7em;
	color: grey;
}

p {
	font-size: 0.8em;
	line-height: 1em;
}

.text-small {
	font-size: 0.8em;
}

model-viewer {
	width: auto;
	height: 50px;
}

td {
	height: 50px;
	vertical-align: middle;
}
</style>