var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [_vm._v(_vm._s(_vm.room.name))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.humanCategory))]),
    _vm._v(" "),
    _c("td", [
      _vm._v(
        _vm._s(_vm.room.players_in_room) + "/" + _vm._s(_vm.room.capacity)
      ),
    ]),
    _vm._v(" "),
    _c("td", [
      _vm._v(_vm._s(new Date(_vm.room.created_at).toLocaleDateString())),
    ]),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [
      _c("i", { staticClass: "small", staticStyle: { color: "grey" } }, [
        _vm._v("N/A"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }