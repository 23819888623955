<template>
	<div class="collection">
		<div class="row" v-if="!$store.state.wallet.connected">
<!--			<Phantom class="btn btn-primary mt-3"></Phantom>-->
<!--			<MultiWallet class="mt-3"></MultiWallet>-->
			<router-link to="/login" class="mt-3 btn btn-primary">Login</router-link>
		</div>

		<div class="row" v-if="$store.state.wallet.connected">

			<div class="mt-5 text-center" v-if="parsedNFTs.length === 0">
				<i>No Items Detected</i>
			</div>

			<div v-if="ua !== null" class="col-sm-6 col-md-3" v-for="(model,key) in parsedNFTs" :key="model.id"
					 v-on:click="addNFT(model)">
				<CatalogueListItem :active="active === model.name" :item="model.sprite_uri" :poster="model.poster_uri" :name="model.name"></CatalogueListItem>
			</div>
		</div>
	</div>
</template>

<script>
import {UserAssets} from "../../js/util/user_assets";
import Phantom from "../wallet/Phantom";
import CatalogueListItem from "../editor/menus/CatalogueListItem";
import MultiWallet from "../wallet/MultiWallet";

export default {
	name: "UserNFTCollection",
	components: {MultiWallet, Phantom, CatalogueListItem},
	props: {
		filter: {
			type: String,
			default: "",
		},
		active: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			search: '',
			ua: null,
		}
	},
	watch: {
		'$store.state.wallet.address'() {
			if (this.ua)
				this.ua.getAccountInfo(this.$store.state.wallet.address)
		}
	},
	computed: {
		parsedNFTs: function () {
			if (!this.ua)
				return [];

			const nfts = [];

			for (let i = 0; i < this.ua.ownedNFT.length; i++) {
				const _n = this.ua.ownedNFT[i];

				// console.log("Parsing nft", _n)
				if (this.shouldFilterType(_n.metadata.properties?.files))
					continue;

				const nft = {
					id: _n.id,
					name: _n.name,
					sprite_uri: _n.metadata.animation_url ? _n.metadata.animation_url : _n.image,
					poster_uri: _n.image,
					original: _n,
					files: _n.metadata.properties?.files
				};

				if (_n.nftAddress) {
					nft.nftAddress = _n.nftAddress;
				}

				// console.log("Parsing nft", _n, nft)
				nfts.push(nft);
			}

			return nfts;
		}
	},
	methods: {

		shouldFilterType(files = []) {
			if (this.filter === "")
				return false;

			if (!files)
				return true;

			console.log("Filtering: ", this.filter, files);
			for (let i = 0; i < files.length; i++) {
				if (files[i].type === this.filter)
					return false;
			}
			return true;
		},

		addNFT(model) {
			console.log("Adding NFT", model);
			this.$emit("add", model);
		},
	},
	mounted() {
		this.ua =  this.$store.state.profile.userAssets

		if (this.$store.state.wallet.connected) {
			this.ua.getAccountInfo(this.$store.state.wallet.address)
		}
	}
}
</script>

<style scoped>

</style>
