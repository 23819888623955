var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c(
      "td",
      [
        _vm.land.metadata.animation_url
          ? _c("model-viewer", {
              attrs: {
                src: _vm.land.metadata.animation_url,
                "auto-rotate": "",
                poster: _vm.land.image,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.land.metadata.animation_url
          ? _c("img", {
              staticClass: "img-fluid",
              staticStyle: { height: "40px" },
              attrs: { alt: "", src: _vm.land.image },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _c("h4", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.land.name))]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.building.token_addr
      ? _c(
          "td",
          [
            _c(
              "router-link",
              {
                staticClass: "row",
                attrs: { to: `/buildings/${_vm.building.token_addr}` },
              },
              [
                _vm.building.logo_src
                  ? _c("img", {
                      staticClass: "col-auto img-fluid oasis-icon",
                      attrs: { src: _vm.building.logo_src, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("h4", { staticClass: "col mt-2" }, [
                  _vm._v(_vm._s(_vm.building.name)),
                ]),
              ]
            ),
          ],
          1
        )
      : _c("td", [
          _c("h5", { staticClass: "mt-2 text-center" }, [
            _vm._v(_vm._s(_vm.building.name)),
          ]),
        ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "router-link",
          {
            staticClass: "btn btn-primary btn-sm btn-block text-small",
            attrs: { to: `/land/${_vm.land.mint}` },
          },
          [_vm._v("Manage")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", [_c("h4", { staticClass: "mt-2" }, [_vm._v("1x1")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }