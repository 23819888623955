<template>
	<div class="sup d-inline">
		<i class="fa fa-info-circle"></i>
		<span class="tooltiptext p-2">{{ message }}</span>
	</div>
</template>

<script>
export default {
	name: "Tooltip",
	props: {
		message: {
			type: String,
			required: true
		},
	}
}
</script>

<style scoped>
.svg-inline--fa.fa-circle-info {
	height: 0.6em;
	cursor: pointer;
}

.tooltip {
	display: inline-block;
}

.sup .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
}

.sup:hover .tooltiptext {
	visibility: visible;
}

/* Bottom tooltip */
.sup .tooltiptext {
	width: 200px;
	top: 150%;
	left: 50%;
	text-align: left;
	margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
</style>