<template>
	<tr>
		<td v-if="metaverse">
			<div class="row">
				<div class="col-auto p-0">
					<img class="img-fluid oasis-icon" v-if="metaverse.logo_src" :src="metaverse.logo_src">
				</div>
				<div class="col text-small text-start">
					<h4><router-link :to="`/metaverse/${metaverse.uuid}`">{{ metaverse.name }}</router-link></h4>
					<h5>{{ metaverse.description ? metaverse.description : 'No description' }}</h5>
					<h5 class="white mt-1">Updated: {{ lastUpdate }}</h5>
				</div>
			</div>
		</td>

		<td>
			<router-link v-if="building.name !== ''" :to="`/buildings/${building.token_addr}`" class="row">
				<img v-if="building.logo_src" class="col-auto img-fluid oasis-icon" :src="building.logo_src" alt="">
				<h4 class="col mt-2">{{ building.name }}</h4>
			</router-link>
			<h5 class="mt-3" v-else>Unassigned</h5>
		</td>

		<td>
			<!--			<router-link v-if="metaverse" :to="`/metaverse/${land.mint}/manifest`" class="btn btn-outline-secondary btn-sm btn-block text-small mt-2">Edit</router-link>-->
			<router-link v-if="metaverse" :to="`/metaverse/${land.uuid}/edit?debug=true`" class="btn btn-outline-secondary btn-sm btn-block text-small">Edit</router-link>
		</td>

		<td>
			<!--			<router-link v-if="metaverse" :to="`/metaverse/${land.mint}/manifest`" class="btn btn-outline-secondary btn-sm btn-block text-small mt-2">Edit</router-link>-->
			<router-link v-if="metaverse" :to="`/metaverse/${land.uuid}/manifest`" class="btn btn-outline-light btn-sm btn-block text-small">Manage</router-link>
		</td>
		<td>
			<router-link v-if="metaverse" :to="`/metaverse/${land.uuid}/visit`" class="btn btn-primary btn-sm btn-block text-small">Visit</router-link>
			<router-link v-else :to="`/land/${land.mint}/create`" class="btn btn-info btn-sm btn-block text-small mt-2">Build</router-link>
		</td>

	</tr>
</template>

<script>
import Metaverse from "../../js/api/metaverse"
import Logo from "../../js/api/logo"
import "@google/model-viewer"

export default {
	name: "OasisRow",
	props: {
		land: {
			type: Object,
			required: true
		},
		modelStyle: {
			type: Object,
			required: false,
		}
	},
	computed: {
		lastUpdate() {
			return new Date(this.metaverse.updated_at).toDateString()
		},
	},
	data() {
		return {
			metaverse: null,
			building: {
				name: ""
			}
		}
	},
	mounted() {
		Metaverse.show(this.land.mint || this.land.uuid).then((resp) => {
			this.metaverse = resp.data
			if (!this.metaverse.building)
				return

			this.building = this.metaverse.building
			if (this.building.logo_id !== 0 && this.building.logo_src === "")
				Logo.showID(this.building.logo_id).then(r => {
					this.building.logo_src = r.data.public_uri
				})

		}).catch((err) => {
			// console.error("Unable to get metaverse details", err)
			//Metaverse doesnt exist
		})
	}
}
</script>

<style scoped>
.alert h3 {
	color: white;
	margin-bottom: 0;
}

h4 {
	font-size: 1em;
}

h5 {
	font-size: 0.7em;
	color: grey;
}

p {
	font-size: 0.8em;
	line-height: 1em;
}

.text-small {
	font-size: 0.8em;
}

model-viewer {
	width: auto;
	height: 50px;
}

.meta-detail {
	border: 1px solid white;
	border-radius: 9px;
	background: rgba(0, 0, 0, 0.5);
}

</style>