var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "collection" }, [
    !_vm.$store.state.wallet.connected
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "router-link",
              { staticClass: "mt-3 btn btn-primary", attrs: { to: "/login" } },
              [_vm._v("Login")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.$store.state.wallet.connected
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _vm.parsedNFTs.length === 0
              ? _c("div", { staticClass: "mt-5 text-center" }, [
                  _c("i", [_vm._v("No Items Detected")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.parsedNFTs, function (model, key) {
              return _vm.ua !== null
                ? _c(
                    "div",
                    {
                      key: model.id,
                      staticClass: "col-sm-6 col-md-3",
                      on: {
                        click: function ($event) {
                          return _vm.addNFT(model)
                        },
                      },
                    },
                    [
                      _c("CatalogueListItem", {
                        attrs: {
                          active: _vm.active === model.name,
                          item: model.sprite_uri,
                          poster: model.poster_uri,
                          name: model.name,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e()
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }