<template>
	<div class="action">
		<form class="menu">
			<div class="form-group">
				<label>Chain</label>
				<!--				TODO Verify chain ID's-->
				<select :value="form.chain_id" class="form-control">
					<option value="0">Solana</option>
					<option disabled value="1">Ethereum (Coming Soon!)</option>
					<option disabled value="2">BSC (Coming Soon!)</option>
					<option disabled value="3">Matic (Coming Soon!)</option>
				</select>
			</div>

			<div class="form-group">
				<label>Token Address</label>
				<input v-model="form.token_addr" class="form-control" placeholder="On-Chain address of the token">
			</div>

			<hr>

			<div class="form-group">
				<label>For Sale </label>
				<input v-model="form.is_on_sale" type="checkbox">
			</div>

			<div v-show="form.is_on_sale">
				<div class="form-group">
					<label>Marketplace</label>
					<select class="form-control" v-model="form.sale_platform">
						<option v-for="(platform,key) in salesPlatforms" :key="key" :value="key">{{ platform }}</option>
					</select>
				</div>

				<div class="form-group" v-show="form.is_on_sale">
					<label>Sale Link</label>
					<input v-model="form.sale_src" class="form-control" :placeholder="exampleSaleLink">
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default {
	name: "ShowSingleNFT",
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				token_addr: "",
				mint_addr: "",
				chain_id: 0,
				is_on_sale: false,
				sale_platform: 0,
				sale_src: ""
			}
		}
	},
	computed: {
		exampleSaleLink() {
			let uri;

			switch (this.form.sale_platform) {
				case 0: //Magic Eden
				default:
					uri = "https://hyperspace.xyz/token/CyHQgZR6hLJJJzQXT5yqFji9Rj7xJN6JTdFf4dDvRT6r";
					break;

				case 1: //Opensea
					uri = "https://opensea.io/assets/0x60e4d786628fea6478f785a6d7e704777c86a7c6/2461";
					break;
			}

			return uri
		},

		salesPlatforms() {
			const platforms = []

			switch (this.form.chain_id) {
				case 0:
					platforms.push("Magic Eden")
					platforms.push("Opensea")
					break;
				default:
					platforms.push("Magic Eden")
					platforms.push("Opensea")
			}

			return platforms
		}
	},
}
</script>

<style scoped>

</style>
