

export default {
	props: {
		alphaId: {
			type: String
		},
		action: {
			type: Object,
		},
		interactionType: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			form: {},
		};
	},
	watch: {
		form: {
			handler(newValue) {
				this.form = newValue
				this.save()
			},
			deep: true,
		}
	},
	methods: {
		save() {
			this.$emit("save", {
				[this.interactionType]: {
					...this.action[this.interactionType],
					data: this.form,
				},
			});
		}
	},
	mounted() {
		const keys = Object.keys(this.form)

		for(let i=0; i<keys.length; i++) {
			const value = this.action[this.interactionType]?.data?.[keys[i]]

			if (value) {
				this.form[keys[i]] = value
			}
		}
	}
}
