var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `uploaded-models card card-body p-0 my-2 ${
        _vm.active ? "active" : ""
      }`,
    },
    [
      _vm.isPosterImage || _vm.isItemModel || _vm.isItemImage
        ? _c(
            "div",
            {
              staticClass: "media-container",
              style: _vm.height ? `max-height: ${_vm.height}px` : "",
            },
            [
              _vm.isItemModel
                ? _c(
                    "model-viewer",
                    {
                      attrs: {
                        src: _vm.item,
                        poster: _vm.poster,
                        "seamless-poster": _vm.poster !== "",
                        reveal: _vm.poster === "" ? "auto" : "interaction",
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "img-container",
                        style: { backgroundImage: `url(${_vm.poster})` },
                        attrs: { slot: "poster" },
                        slot: "poster",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.isPosterImage && !_vm.isItemModel) || _vm.isItemImage
                ? _c("div", {
                    staticClass: "img-container",
                    style: { backgroundImage: `url(${_vm.image})` },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isPosterImage && !_vm.isItemModel && !_vm.isItemImage
        ? _c("div", { staticClass: "invalid small" }, [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v("Unable to load preview"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h6", { staticClass: "mt-2 text-center" }, [
        _vm._v(_vm._s(_vm.name.substring(0, 24) || "Unknown")),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }