<template>
	<tr>
		<td>
			<model-viewer v-if="land.metadata.animation_url" :src="land.metadata.animation_url" auto-rotate
					:poster="land.image"></model-viewer>

			<img alt="" v-if="!land.metadata.animation_url" class="img-fluid" style="height: 40px" :src="land.image">
		</td>
		<td>
			<h4 class="mt-2">{{ land.name }}</h4>
		</td>

		<td>
			<h4 class="mt-2">1x1</h4>
		</td>


		<td v-if="building.token_addr">
			<router-link :to="`/buildings/${building.token_addr}`" class="row">
				<img v-if="building.logo_src" class="col-auto img-fluid oasis-icon" :src="building.logo_src" alt="">
				<h4 class="col mt-2">{{ building.name }}</h4>
			</router-link>
		</td>
		<td v-else>
			<h5 class="mt-2 text-center">{{ building.name }}</h5>
		</td>

		<!--		<td v-if="!metaverse" class="text-start">-->
		<!--			<h5 class="mt-3">Empty Plot</h5>-->
		<!--		</td>-->

		<!--		<td v-if="metaverse">-->
		<!--			<div class="row">-->
		<!--				<div class="col-auto p-0">-->
		<!--					<img class="img-fluid oasis-icon" v-if="metaverse.logo_src" :src="metaverse.logo_src">-->
		<!--				</div>-->
		<!--				<div class="col text-small text-start">-->
		<!--					<h4>{{ metaverse.name }}</h4>-->
		<!--					<h5>{{ metaverse.description ? metaverse.description : 'No description' }}</h5>-->
		<!--					<h5 class="white mt-1">Updated: {{lastUpdate}}</h5>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</td>-->

		<!--		<td>-->
		<!--&lt;!&ndash;			<router-link v-if="metaverse" :to="`/metaverse/${land.mint}/manifest`" class="btn btn-outline-secondary btn-sm btn-block text-small mt-2">Edit</router-link>&ndash;&gt;-->
		<!--			<router-link v-if="metaverse" :to="`/metaverse/${land.mint}/edit?debug=true`" class="btn btn-outline-secondary btn-sm btn-block text-small mt-2">Edit</router-link>-->
		<!--		</td>-->
		<td>
			<router-link :to="`/land/${land.mint}`" class="btn btn-primary btn-sm btn-block text-small">Manage</router-link>
			<!--			<router-link v-else :to="`/buildings/create?land=${land.mint}`" class="btn btn-info btn-sm btn-block text-small mt-2">Build</router-link>-->
		</td>

	</tr>
</template>

<script>
import Metaverse from "../../js/api/metaverse"
import Land from "../../js/api/land"
import Logo from "../../js/api/logo"
import "@google/model-viewer"

export default {
	name: "LandRow",
	props: {
		land: {
			type: Object,
			required: true
		},
		modelStyle: {
			type: Object,
			required: false,
		}
	},
	computed: {
		lastUpdate() {
			return new Date(this.metaverse.updated_at).toDateString()
		},

		x() {
			if(this.land.metadata.attributes)
			return this.land.metadata.attributes?.find(t => t.trait_type === "X").value

			const nameSplit = this.land.name.split(",")
			return nameSplit[0].slice(nameSplit[0].indexOf("(")+1)
		},

		y() {
			if(this.land.metadata.attributes)
			return this.land.metadata.attributes?.find(t => t.trait_type === "Y").value

			const nameSplit = this.land.name.split(",")
			return nameSplit[1].slice(1, nameSplit[1].indexOf(")"))
		}
	},
	data() {
		return {
			metaverse: null,
			building: {
				name: "Empty"
			}
		}
	},
	mounted() {
		Land.showBuilding(this.x,this.y).then(r => {
			this.building = r.data
			if (this.building.logo_id !== 0 && this.building.logo_src === "")
				Logo.showID(this.building.logo_id).then(r => {
					this.building.logo_src = r.data.public_uri
				}).catch(e => {
					console.error("Unable to load logo", e)
				})
		}).catch(e => {
			if (e.response.status === 404)
				return; //Nothing built

			console.error(`Unable to load building on land ${this.x},${this.y}`, e)
		})
	}
}
</script>

<style scoped>
.alert h3 {
	color: white;
	margin-bottom: 0;
}

h4 {
	font-size: 1em;
}

h5 {
	font-size: 0.7em;
	color: grey;
}

p {
	font-size: 0.8em;
	line-height: 1em;
}

.text-small {
	font-size: 0.8em;
}

model-viewer {
	width: auto;
	height: 40px;
}

.meta-detail {
	border: 1px solid white;
	border-radius: 9px;
	background: rgba(0, 0, 0, 0.5);
}
</style>