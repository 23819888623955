var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _vm.metaverse
      ? _c("td", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-auto p-0" }, [
              _vm.metaverse.logo_src
                ? _c("img", {
                    staticClass: "img-fluid oasis-icon",
                    attrs: { src: _vm.metaverse.logo_src },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col text-small text-start" }, [
              _c(
                "h4",
                [
                  _c(
                    "router-link",
                    { attrs: { to: `/metaverse/${_vm.metaverse.uuid}` } },
                    [_vm._v(_vm._s(_vm.metaverse.name))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h5", [
                _vm._v(
                  _vm._s(
                    _vm.metaverse.description
                      ? _vm.metaverse.description
                      : "No description"
                  )
                ),
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "white mt-1" }, [
                _vm._v("Updated: " + _vm._s(_vm.lastUpdate)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.building.name !== ""
          ? _c(
              "router-link",
              {
                staticClass: "row",
                attrs: { to: `/buildings/${_vm.building.token_addr}` },
              },
              [
                _vm.building.logo_src
                  ? _c("img", {
                      staticClass: "col-auto img-fluid oasis-icon",
                      attrs: { src: _vm.building.logo_src, alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("h4", { staticClass: "col mt-2" }, [
                  _vm._v(_vm._s(_vm.building.name)),
                ]),
              ]
            )
          : _c("h5", { staticClass: "mt-3" }, [_vm._v("Unassigned")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.metaverse
          ? _c(
              "router-link",
              {
                staticClass:
                  "btn btn-outline-secondary btn-sm btn-block text-small",
                attrs: { to: `/metaverse/${_vm.land.uuid}/edit?debug=true` },
              },
              [_vm._v("Edit")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.metaverse
          ? _c(
              "router-link",
              {
                staticClass:
                  "btn btn-outline-light btn-sm btn-block text-small",
                attrs: { to: `/metaverse/${_vm.land.uuid}/manifest` },
              },
              [_vm._v("Manage")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.metaverse
          ? _c(
              "router-link",
              {
                staticClass: "btn btn-primary btn-sm btn-block text-small",
                attrs: { to: `/metaverse/${_vm.land.uuid}/visit` },
              },
              [_vm._v("Visit")]
            )
          : _c(
              "router-link",
              {
                staticClass: "btn btn-info btn-sm btn-block text-small mt-2",
                attrs: { to: `/land/${_vm.land.mint}/create` },
              },
              [_vm._v("Build")]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }