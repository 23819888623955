var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sup d-inline" }, [
    _c("i", { staticClass: "fa fa-info-circle" }),
    _vm._v(" "),
    _c("span", { staticClass: "tooltiptext p-2" }, [
      _vm._v(_vm._s(_vm.message)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }