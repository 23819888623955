var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("td", [
      _vm.logo
        ? _c("img", {
            staticClass: "building-logo-sm",
            attrs: { src: _vm.logo, alt: "" },
          })
        : _c("h5", [_vm._v("No Logo")]),
    ]),
    _vm._v(" "),
    _c("td", [_vm._v("\n\t\t" + _vm._s(_vm.building.name) + "\n\t")]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.firstLandDistrict))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.firstLandSector))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "router-link",
          {
            staticClass: "btn btn-primary btn-sm btn-block text-small",
            attrs: { to: `/buildings/${_vm.building.token_addr}` },
          },
          [_vm._v("Manage")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }