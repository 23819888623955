<template>
	<tr>
<!--		<td>{{room.token_id}}</td>-->
		<td>{{room.name}}</td>
		<td>{{humanCategory}}</td>
		<td>{{room.players_in_room}}/{{room.capacity}}</td>
		<td>{{new Date(room.created_at).toLocaleDateString()}}</td>
		<td>
<!--			<router-link :to="`/metaverse/${room.token_id}/visit`" class="btn btn-info btn-sm btn-block text-small">View</router-link>-->
			<i class="small" style="color: grey">N/A</i>
		</td>
	</tr>
</template>

<script>
import Metaverse from "../../js/api/metaverse"
import "@google/model-viewer"

export default {
	name: "RoomRow",
	props: {
		room: {
			type: Object,
			required: true
		},
		modelStyle: {
			type: Object,
			required: false,
		}
	},
	data() {
		return {
			metaverse: null,
			categories: {
				0: "Default",
				1: "Personal",
				2: "Casino",
				3: "Sponsor",
				4: "Community",
				5: "Founder",
				6: "Public"
			}
		}
	},
	computed: {
		humanCategory: function() {
			return this.categories[this.room.category] || "Unknown"
		}
	},
	mounted() {
	}
}
</script>

<style scoped>
.alert h3 {
	color: white;
	margin-bottom: 0;
}

h4 {
	font-size: 1em;
}

h5 {
	font-size: 0.7em;
	color: grey;
}

p {
	font-size: 0.8em;
	line-height: 1em;
}

.text-small {
	font-size: 0.8em;
}

model-viewer {
	width: auto;
	height: 50px;
}

.meta-detail {
	border: 1px solid white;
	border-radius: 9px;
	background: rgba(0, 0, 0, 0.5);
}

.oasis-icon {
	max-height: 50px;
}
</style>