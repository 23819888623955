<template>
	<div :class="`uploaded-models card card-body p-0 my-2 ${active? 'active': ''}`">
		<div
      class="media-container"
      v-if="isPosterImage || isItemModel || isItemImage"
      :style="height ? `max-height: ${height}px` : ''"
    >
			<model-viewer v-if="isItemModel" :src="item" :poster="poster" :seamless-poster="poster !== ''"
										:reveal="poster === '' ? 'auto' : 'interaction'">
				<div slot="poster" class="img-container" :style="{backgroundImage: `url(${poster})`}"></div>
			</model-viewer>

			<div v-if="(isPosterImage && !isItemModel) || isItemImage" class="img-container" :style="{backgroundImage: `url(${image})`}">
			</div>
		</div>

		<div class="invalid small" v-if="!isPosterImage && !isItemModel && !isItemImage">
			<p class="mb-0">Unable to load preview</p>
		</div>

		<h6 class="mt-2 text-center">{{ name.substring(0,24) || 'Unknown' }}</h6>
	</div>
</template>

<script>
import {isImage, isModel} from "../../../js/util/modelDetector";

export default {
	name: "CatalogueListItem",
	props: {
		item: {
			type: String,
			required: true
		},
		poster: {
			type: String,
			default: ''
		},
		active: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
      default: () => '',
		},
    height: {
      type: Number,
      default: () => 100
    }
	},
	computed: {
		isItemModel() {
			return isModel(this.item);
		},
		isItemImage() {
			return isImage(this.item);
		},
		isPosterImage() {
			return isImage(this.poster);
		},

		image() {
			if (this.isPosterImage) {
				return this.poster;
			}

			if (this.isItemImage) {
				return this.item;
			}

			return null
		},
	}
}
</script>

<style scoped>
model-viewer {
	width: 100%;
	height: 100px;
}

.invalid {
	height: 100px;
	padding-top: 45px;
	/*padding-bottom: 70px;*/
	/*max-height: 165px;*/
}

h6 {
	font-size: 0.8em;
}

.active {
	border: 2px solid #00bcd4;
	box-shadow: 0px 0px 10px 0px #00bcd4;
}

.img-container {
	height: 100px;
	background-size: cover;
	background-position: center;
	background-color: rgba(255, 255, 255, 0.1);
}

.media-container {
	height: 100px;
	overflow: hidden;
}

.media-container img {
	min-height: 98px;
	height: 100%;
}

.uploaded-models {
	max-height: 200px;
	overflow: hidden;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
}

.uploaded-models:hover {
	transform: scale(1.05);
	border: 1px solid white;
}
</style>
