var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        target: "_blank",
        href: "https://hyperspace.xyz/collection/5SMfBS7H47YHC3npgeCaMjQw8FYs4rtuhE2gNYo8S3jY",
      },
    },
    [_c("HyperSpaceIcon"), _vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.text))])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }