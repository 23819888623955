<template>
	<a target="_blank" href="https://hyperspace.xyz/collection/5SMfBS7H47YHC3npgeCaMjQw8FYs4rtuhE2gNYo8S3jY" class="btn btn-secondary btn-sm">
		<HyperSpaceIcon></HyperSpaceIcon>
		<span>{{ text }}</span></a>
</template>

<script>
import HyperSpaceIcon from "./HyperSpaceIcon";
export default {
	name: "MarketplaceButton",
	components: {HyperSpaceIcon},
	props:{
		text: {
			type: String,
			default: "SELL LAND"
		}
	}
}
</script>

<style scoped>

</style>